// dariWords.js

export const dariWords = [
    {
      dari: 'خانه', // Home in Dari
      english: 'Home'
    },
    {
      dari: 'کتاب', // Book in Dari
      english: 'Book'
    },
    {
      dari: 'مدرسه', // School in Dari
      english: 'School'
    },
    {
      dari: 'دوست', // Friend in Dari
      english: 'Friend'
    },
    {
      dari: 'سلام', // Hello in Dari
      english: 'Hello'
    }
  ];