// dariAlphabet.js

export const dariAlphabet = [
    {
      dari: 'ا',
      english: 'Alef'
    },
    {
      dari: 'ب',
      english: 'Be'
    },
    {
      dari: 'پ',
      english: 'Pe'
    },
    {
      dari: 'ت',
      english: 'Te'
    },
    {
      dari: 'ث',
      english: 'Se'
    },
    {
      dari: 'ج',
      english: 'Jim'
    },
    {
      dari: 'چ',
      english: 'Che'
    },
    {
      dari: 'ح',
      english: 'He'
    },
    {
      dari: 'خ',
      english: 'Khe'
    },
    {
      dari: 'د',
      english: 'Dal'
    },
    {
      dari: 'ذ',
      english: 'Zal'
    },
    {
      dari: 'ر',
      english: 'Re'
    },
    {
      dari: 'ز',
      english: 'Ze'
    },
    {
      dari: 'ژ',
      english: 'Zhe'
    },
    {
      dari: 'س',
      english: 'Sin'
    },
    {
      dari: 'ش',
      english: 'Shin'
    },
    {
      dari: 'ص',
      english: 'Sad'
    },
    {
      dari: 'ض',
      english: 'Zad'
    },
    {
      dari: 'ط',
      english: 'Ta'
    },
    {
      dari: 'ظ',
      english: 'Za'
    },
    {
      dari: 'ع',
      english: 'Ain'
    },
    {
      dari: 'غ',
      english: 'Ghain'
    },
    {
      dari: 'ف',
      english: 'Fe'
    },
    {
      dari: 'ق',
      english: 'Qaf'
    },
    {
      dari: 'ک',
      english: 'Kaf'
    },
    {
      dari: 'گ',
      english: 'Gaf'
    },
    {
      dari: 'ل',
      english: 'Lam'
    },
    {
      dari: 'م',
      english: 'Meem'
    },
    {
      dari: 'ن',
      english: 'Noon'
    },
    {
      dari: 'و',
      english: 'Vav'
    },
    {
      dari: 'ه',
      english: 'He'
    },
    {
      dari: 'ی',
      english: 'Ye'
    }
  ];
  