import React, { useState, useEffect } from 'react';
import WordDisplay from './WordDisplay';
import QuestionSection from './QuestionSection';
import Button from './Button';
import { dariWords } from './dariWords'; // Import the Dari words list

function LearningPage() {
  // Initialize with a random Dari word
  const initialRandomIndex = Math.floor(Math.random() * dariWords.length);
  const initialWord = dariWords[initialRandomIndex].dari;
  
  const [currentWord, setCurrentWord] = useState(initialWord);
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0); // Index of the highlighted letter

  const handleNext = () => {
    // Logic to move to the next letter
    setCurrentLetterIndex((prevIndex) => prevIndex + 1);
  };

  const handleCorrectAnswer = () => {
    if (currentLetterIndex === currentWord.length) {
      // Randomly pick a new word
      const randomIndex = Math.floor(Math.random() * dariWords.length);
      const newWord = dariWords[randomIndex].dari;
      setCurrentWord(newWord);

      // Reset the index
      setCurrentLetterIndex(0);
    } else {
      // Logic to move to the next letter
      setCurrentLetterIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <div>
      <WordDisplay currentWord={currentWord} currentLetterIndex={currentLetterIndex} />
      <QuestionSection 
        currentWord={currentWord} 
        currentLetterIndex={currentLetterIndex} 
        onCorrectAnswer={handleCorrectAnswer} // Pass the callback for correct answers
      />
    </div>
  );
}

export default LearningPage;
