import React, { useState, useEffect } from 'react';

function Option({ isSelected, label, isCorrect, onClick }) {
  const [className, setClassName] = useState('option');

  useEffect(() => {
    if (isSelected) {
      setClassName(`option ${isCorrect ? 'option-correct' : 'option-incorrect'}`);
    } else {
      setClassName('option');
    }

    const timer = setTimeout(() => {
      setClassName('option');
    }, 500); // Reset after 0.5 seconds

    return () => clearTimeout(timer);
  }, [isSelected, isCorrect]);

  return (
    <div onClick={onClick} className={className}>
      {label}
    </div>
  );
}

export default Option;
