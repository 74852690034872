import React, { useState, useEffect } from 'react';
import Option from './Option';
import { dariAlphabet } from './dariAlphabet'; // Import the Dari alphabet list
import { dariWords } from './dariWords'

function QuestionSection({ currentWord, currentLetterIndex, onCorrectAnswer }) {
  const [selectedOption, setSelectedOption] = useState(null); // Selected option
  const [isCorrect, setIsCorrect] = useState(null); // Whether the selected option is correct or not
  const [options, setOptions] = useState([]); // Multiple-choice options

  // Reset state when the question changes
  useEffect(() => {
      setSelectedOption(null);
      setIsCorrect(null);
    }, [currentLetterIndex]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    const answer = currentLetterIndex === currentWord.length ? currentWord : currentWord[currentLetterIndex]
    if (option.dari === answer) {
        setIsCorrect(true);
        setTimeout(() => {
        if (typeof onCorrectAnswer === 'function') {
            onCorrectAnswer();  // Call the callback
        }
        }, 500); // Delay of 1 second before moving to next question
    } else {
        setIsCorrect(false);
    }
  };

useEffect(() => {
    if (currentLetterIndex === currentWord.length) {
      // Pick 3 random words that are not the current word
      const randomOptions = [];
      while (randomOptions.length < 3) {
        const randomIndex = Math.floor(Math.random() * dariWords.length);
        const randomWord = dariWords[randomIndex];
        if (randomWord.dari !== currentWord && !randomOptions.includes(randomWord)) {
          randomOptions.push(randomWord);
        }
      }

      // Add the correct option
      const currentWordInfo = dariWords.find(item => item.dari === currentWord);
      setOptions([...randomOptions, currentWordInfo].sort(() => Math.random() - 0.5));
    } else {
        const currentLetter = currentWord[currentLetterIndex];
        const currentLetterInfo = dariAlphabet.find(item => item.dari === currentLetter);

        // Get three random letters that are not the current one
        const randomOptions = [];
        while (randomOptions.length < 3) {
            const randomIndex = Math.floor(Math.random() * dariAlphabet.length);
            const randomLetter = dariAlphabet[randomIndex];
            if (randomLetter.dari !== currentLetter && !randomOptions.includes(randomLetter)) {
                randomOptions.push(randomLetter);
            }
        }
        // Add the correct option
        setOptions([...randomOptions, currentLetterInfo].sort(() => Math.random() - 0.5));
    }
  }, [currentLetterIndex, currentWord]);
  
  const questionText = currentLetterIndex === currentWord.length
    ? <p>What is the meaning of this word?</p>
    : <p>What is the English name of letter #{currentLetterIndex + 1}</p>;

  return (
    <div className='text-container'>
      {questionText}
      {options.map((option, index) => (
        <Option 
          key={`${currentLetterIndex}-${index}`}
          isSelected={selectedOption === option}
          label={option.english}
          isCorrect={isCorrect}
          onClick={() => handleOptionClick(option)}
        />
      ))}
    </div>
  );
}

export default QuestionSection;
