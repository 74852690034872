import React from 'react';
import { dariAlphabet } from './dariAlphabet'; // Import the Dari alphabet list

function WordDisplay({ currentWord, currentLetterIndex }) {
  // Split the Dari word into its individual letters
  const letters = Array.from(currentWord);
  
  // Slice the array up to current index and map to their English names
  const englishNames = letters.slice(0, currentLetterIndex).reverse().map(dariLetter => {
    const info = dariAlphabet.find(item => item.dari === dariLetter);
    return info ? info.english : '';
  }).join(' ← ');

  return (
    <div className='text-container'>
      <div className='dariWordDisplay'>
        {letters.join('')}
      </div>
      <div className='englishWordDisplay'>
        {'' + englishNames}
      </div>
    </div>
  );
}

export default WordDisplay;
