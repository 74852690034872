import React, { useState } from 'react';
import NavBar from './NavBar';
import HomePage from './HomePage';
import LearningPage from './LearningPage';
import AboutPage from './AboutPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'; 

function App() {
  const [globalProgress, setGlobalProgress] = useState({});
  return (<div>
    <LearningPage></LearningPage>
  </div>);
}

export default App;
